@import "../../variables.scss";

svc-tab-json-editor-textarea {
  width: 100%;
  height: 100%;
  background: $background-dim;
}

.svc-json-editor-tab__content {
  position: relative;
  height: 100%;
}

.svc-json-editor-tab__content-area {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
}

.svc-json-editor-tab__errros_button {
  position: absolute;
  top: 0%;
  right: calc(0% + calc(2 * #{$base-unit}));
  min-height: calc(2.5 * #{$base-unit});
  max-height: calc(2.5 * #{$base-unit});
  border: none;
  background-color: $primary;
  color: $primary-foreground;
  font-weight: bold;
}

.svc-json-editor-tab__content-errors {
  position: absolute;
  right: 0%;
  top: calc(0% + calc(3 * #{$base-unit}));
  max-width: calc(100% - calc(2 * #{$base-unit}));
  padding: $base-unit;

  background-color: $background;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.1));
  border: 1px solid $border;
  border-radius: 1px;

  color: $foreground;
  font-family: monospace;

  span {
    white-space: pre-line;

    >b {
      color: $red;
    }
  }
}

.svc-json-editor-tab__content-errors:empty {
  display: none;
}

.svc-json-errors {
  display: flex;
  max-height: calc(#{$base-unit} * 25 + 5px);
  overflow-y: auto;
  flex-direction: column;
  padding: 0;
  margin: 0;
  border-top: 1px solid $border-inside;
}

.svc-json-errors__item {
  pointer-events: none;
  display: block;
}

.svc-json-error {
  font-family: $font-family;
  font-size: calcSize(2);
  line-height: calcSize(3);
  padding: calcSize(1) calcSize(1) calcSize(1) calcSize(2);
  background: $red-light;
  display: flex;
  align-items: center;
  gap: calcSize(2);
}

.svc-json-errors__item:not(:first-of-type) .svc-json-error {
  border-top: 1px solid $border-inside;
}

.svc-json-error .sv-string-viewer {
  pointer-events: all;
  cursor: pointer;
}

.svc-json-error .sv-string-viewer:hover {
  text-decoration: underline;
}

.svc-json-error__icon {
  fill: $red;
}

.svc-json-error__container {
  display: flex;
  gap: calcSize(1);
}

.svc-json-error__title {
  flex: 1 1 auto;
}

.svc-json-error__fix-button {
  appearance: none;
  flex-grow: 0;
  flex-shrink: 0;
  pointer-events: all;
  padding: 0 calcSize(1);
  width: calcSize(5);
  height: calcSize(3);
  background: $background;
  border: none;
  border-radius: calcSize(0.5);
  box-shadow: $shadow-small;
  cursor: pointer;

  svg {
    fill: $foreground-dim-light;
    width: calcSize(3);
    height: calcSize(3);
  }
}

.svc-json-error__fix-button:hover {
  svg {
    fill: $primary;
  }
}