.spg-checkbox {
  cursor: pointer;
  margin-top: calcSize(2);
}

.spg-checkbox--disabled {
  cursor: default;

  .spg-checkbox {
    cursor: default;
  }

  .spg-checkbox__caption {
    color: $foreground-light;
  }
}

.spg-checkbox__caption {
  font-family: $font-family;
  font-size: calcSize(2);
  color: $foreground;
  line-height: calcSize(3);
}

.spg-checkbox__rectangle {
  background: $background;
  border: 1px solid $border;
  box-sizing: border-box;
  width: calcSize(3);
  height: calcSize(3);
  flex-shrink: 0;
  // padding: calcSize(0.5);
  padding: 1px 1px;
}

.spg-checkbox:hover {
  .spg-checkbox__rectangle {
    background-color: $background-dim;
  }
}

.spg-checkbox--disabled {
  .spg-checkbox__rectangle:hover {
    background: $background;
  }
}

.spg-checkbox__control:focus+.spg-checkbox__rectangle {
  outline: 2px solid $primary;
  outline-offset: -2px;
}

.spg-checkbox .spg-checkbox__svg {
  display: none;
}

.spg-checkbox--checked .spg-checkbox__svg {
  display: block;
  width: calcSize(2.5);
  height: calcSize(2.5);
  background-repeat: no-repeat;
  background-size: calcSize(2) calcSize(2);
  fill: $primary;
}

.spg-checkbox--disabled.spg-checkbox--checked .spg-checkbox__svg {
  fill: $border;
}

.spg-checkbox__control {
  position: fixed;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

//todo: need to hide unneeded icons
.spg-checkbox__hidden {
  display: none;
}

.spg-selectbase {
  border: none;
  padding: 0;
  margin: 0;
}

.spg-selectbase__label {
  display: flex;
  align-items: flex-start;
  gap: calcSize(1);
}

.spg-checkbox {
  .spg-question__description {
    padding: calcSize(1) 0 0 calcSize(4);
  }

  .spg-help-action {
    use {
      pointer-events: none;
    }
  }
}