@import "../variables.scss";

.svc-question-link__set-button {
  font-weight: 600;
  cursor: pointer;
}
.svc-link-value-button {
  margin-left: calcSize(-2);
}
.svc-question-link__clear-button {
  color: $red;
  font-weight: 600;
  cursor: pointer;
}
.svc-question-link__clear-button:focus,
.svc-question-link__clear-button:hover {
  background-color: $red-light;
}
