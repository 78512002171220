@import "../variables.scss";

.svd-simulator {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: content-box;
  transform-origin: 0 0;
  top: 50%;
  left: 50%;
  transform: scale(1.26) translate(-50%, -50%);
  overflow: overlay;
  border: 2px solid $border-inside;
}

.svd-simulator-wrapper {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
  border: 4px solid $background-dim;
  background: $background;
  box-shadow: 0px 16px 32px 0px rgba(0, 0, 0, 0.10), 0px 4px 16px 0px rgba(0, 0, 0, 0.05);
}

.svd-simulator-main {
  min-height: 100%;

  &.svd-simulator-main--desktop {
    min-height: auto;

    &.svd-simulator-main--running {
      height: 100%;
    }
  }

  &.svd-simulator-main--frame {
    display: flex;
    align-items: center;
  }

  &:not(.svd-simulator-main--frame) {
    background-color: transparent;
  }

  .sd-root-modern {
    min-height: 100%;

    .sv-popup:not(.sv-popup--overlay):not(.sv-popup--modal) {
      width: auto;
    }
  }
}

.svd-simulator-content {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  // border-bottom: 1px solid $border-inside;
  box-shadow: inset 0px -1px 0px 0px $border-inside;
  position: relative;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  ::i-block-chrome,
  .svd-simulator-content {
    overflow-y: scroll;
  }
}